<template>
  <b-overlay
    :show="spinnerShow === true"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card-code title="Diabetes Medicine">

      <add-new
        :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
        @refetch-data="refetchData"
      />

      <!-- input search -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
            <b-button
              variant="primary"
              @click="isAddNewSidebarActive = true"
            >
              <span class="text-nowrap">Add New</span>
            </b-button>
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        ref="refListTable"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Name -->
          <div
            v-if="props.column.field === 'name'"
            class="text-nowrap"
          />

          <div
            v-if="props.column.field === 'abbr'"
            class="text-nowrap"
          />

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'active'">
            <b-badge :variant="statusVariant(getActive(props.row.active))">
              {{ getActive(props.row.active) }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item :to="{ name: 'system-diabetes-medicine-edit', params: { id: props.row.id } }">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showDeleteConfirm(props.row.id)">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['2','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap "> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-code>
  </b-overlay>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import AddNew from './AddNew.vue'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    AddNew,
    BOverlay,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      spinnerShow: true,
      isAddNewSidebarActive: false,
      pageLength: 15,
      dir: true,
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Abbreviation',
          field: 'abbr',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Status',
          field: 'active',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Status',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        active: 'light-success',
        inactive     : 'light-danger',
        Resigned     : 'light-warning',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

  created() {
    store
      .dispatch('diabetesMedicineStore/fetchDiabetesMedicines')
      .then(response => {
        this.rows = response.data
        this.spinnerShow = false
      })
      .catch(() => {
        this.$bvToast.toast('Error fetching the records.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      })
  },
  methods: {
    formatDays(valuesArray) {
      return valuesArray.map(value => `${value} `).join(',')
    },
    refetchData() {
      return this.$forceUpdate()
    },

    getActive(status) {
      if (status === 2) return 'pending'
      if (status === 1) return 'active'
      if (status === 0) return 'inactive'
      return 'could not fetch the status'
    },

    showDeleteConfirm(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this record.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            store
              .dispatch('diabetesMedicineStore/removeDiabetesMedicine', id)
            // eslint-disable-next-line no-unused-vars
              .then(response => {
                this.$bvToast.toast('Record Deleted', {
                  title: 'Success',
                  variant: 'success',
                  solid: true,
                })
              })
              .catch(() => {
                this.$bvToast.toast('Error in deleting selected record.', {
                  title: 'Error',
                  variant: 'danger',
                  solid: true,
                })
              })
          }
          // console.log(`${value} ${id}`)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
